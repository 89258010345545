/* eslint-disable no-use-before-define */
import { useState, useEffect } from "react";
import { createFilterOptions } from "@mui/material/Autocomplete";
import { Checkbox, Typography, Autocomplete } from "@mui/material";
import CheckBoxOutlineBlankOutlinedIcon from '@mui/icons-material/CheckBoxOutlineBlankOutlined';
import CheckBoxOutlinedIcon from '@mui/icons-material/CheckBoxOutlined';
import { InputStyle } from "pages/ActivitiesReport";

const MultiSelect = ({
    items,
    label,
    placeholder,
    selectAllLabel,
    noOptionsText,
    onChange, getOptionDisabled
}) => {
    const [selectedOptions, setSelectedOptions] = useState([]);
    const allSelected = items.length === selectedOptions.length;
    const handleToggleOption = selectedOptions =>
        setSelectedOptions(selectedOptions);
    const handleClearOptions = () => setSelectedOptions([]);
    const getOptionLabel = option => `${option.label}`;
    const handleSelectAll = isSelected => {
        if (isSelected) {
            setSelectedOptions(items);
        } else {
            handleClearOptions();
        }
    };

    const handleToggleSelectAll = () => {
        handleSelectAll && handleSelectAll(!allSelected);
    };
    useEffect(() => { onChange(selectedOptions) }, [selectedOptions])
    const handleChange = (event, currSelectedOptions, reason) => {
        if (reason === "selectOption" || reason === "removeOption") {
            if (!!currSelectedOptions.find(option => option.value === "select-all")) {
                handleToggleSelectAll();
                let result = [];
                result = items.filter(el => el.value !== "select-all");
            } else {
                handleToggleOption && handleToggleOption(currSelectedOptions);
            }
        } else if (reason === "clear") {
            handleClearOptions && handleClearOptions();
        }
    };
    const optionRenderer = (props, option, state) => {
        const selectAllProps =
            option.value === "select-all" // To control the state of 'select-all' checkbox
                ? { checked: allSelected }
                : {};
        return (
            <li {...props} key={option.value} >
                <Checkbox
                    color="primary"
                    icon={<CheckBoxOutlineBlankOutlinedIcon sx={{ color: '#3a7735' }} fontSize="small" />}
                    checkedIcon={<CheckBoxOutlinedIcon sx={{ color: '#3a7735' }} fontSize="small" />}
                    style={{ marginRight: 8 }}
                    checked={state.selected}
                    {...selectAllProps}
                />
                <Typography color='inherit' variant="responsiveTextSize">{getOptionLabel(option)}</Typography>
            </li>
        );
    };
    const inputRenderer = params => (<InputStyle  {...params} disabled label={label} />);
    const getOptionSelected = (option, anotherOption) =>
        option.value === anotherOption.value;
    const filter = createFilterOptions();
    return (
        <Autocomplete
            multiple
            getOptionDisabled={getOptionDisabled}
            size="small"
            options={items}
            value={selectedOptions}
            limitTags={2}
            disableCloseOnSelect
            getOptionLabel={getOptionLabel}
            isOptionEqualToValue={getOptionSelected}
            noOptionsText={noOptionsText}
            filterOptions={(options, params) => {
                const filtered = filter(options, params);
                return [{ label: selectAllLabel, value: "select-all" }, ...filtered];
            }}
            defaultValue={[{ label: selectAllLabel, value: "select-all" }]}
            onChange={handleChange}
            renderOption={optionRenderer}
            renderInput={inputRenderer}
            sx={{ margin: '0.9rem 0' }}

        />
    );
};

export default MultiSelect;
